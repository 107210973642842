import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomBar from "./bottombar";
import { Dropdown } from "./dropdown";
import ReactTextareaAutosize from "react-textarea-autosize";
import { SERVER_URL } from "./url";
import axios from "axios";
import formatExpertOptions from "../util/formatExpertOptions";

export const PromptPage = () => {
  const [userName, setUserName] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [input2Value, setInput2Value] = useState("");
  const [loadingTrigger, setLoadingTrigger] = useState(false);
  const [expertOptions, setExpertOptions] = useState([]);
  const [loadingExpertOpts, setLoadingExpertOpts] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [inputFocus, setInputFocus] = useState(false);
  const [expertTags, setExpertTags] = useState([
    {
      actualValue: "",
      currentValue: "",
      placeholder: "Choose/type in your expert...",
      canBeDeleted: false,
      lowOpacity: false,
    },
    {
      actualValue: "",
      currentValue: "",
      placeholder: "Add another expert option...",
      canBeDeleted: false,
      lowOpacity: true,
    },
  ]);
  const [recentOptions, setRecentOptions] = useState([]);

  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    let name = localStorage.getItem("username");
    if (name == null) {
      name = "user";
    }
    setUserName(name);

    const getExpertOptions = async () => {
      setLoadingExpertOpts(true);
      try {
        // Get expert options from backend
        const { data } = await axios.get(SERVER_URL + "api/experts");
        console.log(data.options);
        setExpertOptions(data.options);
        setLoadingExpertOpts(false);
        localStorage.setItem("experts", JSON.stringify(data.options));
      } catch (e) {
        console.error(e);
      }
    };
    getExpertOptions();
  }, []);

  useEffect(() => {
    scrollContainerRef.current.scroll(
      scrollContainerRef.current.scrollWidth,
      0
    );
  }, [expertTags]);

  const handleInput2Change = (e) => {
    setInput2Value(e.target.value);
  };

  const addPrompt = (e) => {
    if (prompts.length === expertTags.length) {
      setExpertTags((state) => [
        ...state,
        {
          actualValue: "",
          currentValue: "",
          placeholder: "Add another expert option...",
          canBeDeleted: true,
          autoFocus: true,
          lowOpacity: false,
        },
      ]);
    }
  };

  const startProgressBar = () => {
    let currentStep = 0;

    let interval = setInterval(() => {
      setProgress(currentStep);
      currentStep++;

      if (currentStep === 100) {
        clearInterval(interval);

        localStorage.setItem("result", true);

        navigate("/result");
      }
    }, 50);
  };

  const modelHandler = () => {
    if (!input2Value || /*prompts.length === 0 ||*/ !/\w+/gi.test(input2Value)) return;

    setLoadingTrigger(true);

    // let question = prompts.join(", ");
    // if (input2Value.length !== 0) {
    //   question = question + ", " + input2Value;
    // }
    if (prompts.length === 0) {
      prompts.push('');
      prompts.push('');
    };

    localStorage.setItem("question", input2Value);
    localStorage.setItem("prompts", JSON.stringify(prompts));

    startProgressBar();
  };

  const keyDownEvent = (event) => {
    if (event.keyCode === 16 && event.key === "Shift") {
      setShiftPressed(true);
      return;
    }
    if (event.keyCode === 13 && event.key === "Enter" && !shiftPressed) {
      event.preventDefault();
      modelHandler();
    }
  };

  const keyUpEvent = (event) => {
    if (event.keyCode === 16 && event.key === "Shift") setShiftPressed(false);
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");

    window.location.href = "/prelogin";
  };

  return (
    <div className="bg-custom-background w-screen h-screen overflow-hidden flex flex-col">
      <div className="flex self-end mr-10 mt-2 mb-2">
        <div className="flex justify-center items-center rounded-full bg-gray-950 py-1 px-6 mr-3">
          <button
            className="text-white text-[12px] font-inter"
            onClick={logout}
          >
            LOG OUT
          </button>
        </div>
        <div className="flex justify-center items-center rounded-full bg-gray-950 py-1 px-6">
          <h1 className="text-white text-[12px] font-inter">
            {userName.toUpperCase()}
          </h1>
        </div>
      </div>

      <div
        style={{ scrollbarWidth: "none" }}
        className="rounded-[15px] overflow-hidden flex justify-center flex-col items-center relative  xl:mx-4 md:ml-1 w-[calc(100%_-_36px)] md:h-[330px] xl:h-[460px] lg:h-[400px] bg-[#0A1822] self-center"
      >
        {!loadingTrigger ? (
          <div className="flex justify-center flex-col items-center w-[50rem] p-5">
            <div className="w-full">
              <label className="block font-inter font-bold text-white ml-6">
                Pick the Expert Perspectives that you need...
              </label>
              <div className="flex items-center w-full mt-2">
                <div
                  className="flex w-full items-center bg-custom-input-color rounded-full p-1.5 gap-2 overflow-x-scroll no-scrollbar"
                  ref={scrollContainerRef}
                >
                  {!loadingExpertOpts ? (
                    <>
                      {expertTags.map((tag, i) => (
                        <Dropdown
                          prompts={prompts}
                          setPrompts={setPrompts}
                          tag={tag}
                          key={"dropdown" + i}
                          expertTags={expertTags}
                          setExpertTags={setExpertTags}
                          setExpertOptions={setExpertOptions}
                          expertOptions={expertOptions}
                          index={i}
                          recentOptions={recentOptions}
                          setRecentOptions={setRecentOptions}
                          isReversed={false}
                          lowOpacity={tag.lowOpacity}
                        />
                      ))}
                    </>
                  ) : (
                    <p className="text-[#FFFFFF99] py-1 px-4 font-interLight font-xl">Loading your experts...</p>
                  )}
                  <button
                    onClick={addPrompt}
                    className="bg-custom-result-chatbg ml-auto me-1 text-white border border-light rounded-full opacity-50 hover:opacity-100 flex items-center justify-center shrink-0 disabled:opacity-50"
                    style={{
                      fontSize: "28px",
                      width: "37px",
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: "5px",
                    }}
                    disabled={expertTags.length >= 5 || loadingExpertOpts}
                    id="add-prompt"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            <div
              className={
                inputFocus
                  ? "w-full mt-4 h-[100px] mb-5 z-50"
                  : "w-full mt-4 h-[100px] mb-5"
              }
            >
              <label className="block font-inter font-bold text-white mt-4 mb-2 ml-6">
                Ask your question to the room...
              </label>
              <div className="w-full mt-2 flex items-center">
                <div className="flex-grow flex items-center">
                  <ReactTextareaAutosize
                    id="input2"
                    type="text"
                    value={input2Value}
                    onChange={handleInput2Change}
                    onKeyDown={keyDownEvent}
                    onKeyUp={keyUpEvent}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    placeholder="i.e. Who's the greatest basketball player of all time?"
                    className="rounded-[20px] py-3 px-5 w-full bg-custom-input-color text-white resize-none font-interLight"
                    maxLength={5000}
                    maxRows={6}
                  />
                </div>
                <button
                  onClick={modelHandler}
                  className={
                    inputFocus
                      ? "bg-custom-result-chatbg font-inter text-white rounded-full px-5 py-3 ml-2 hover:bg-gray-600 flex items-center justify-center place-self-start font-xl border border-white"
                      : "bg-custom-result-chatbg font-inter text-white rounded-full px-5 py-3 ml-2 hover:bg-gray-600 flex items-center justify-center place-self-start font-xl"
                  }
                  disabled={loadingExpertOpts}
                >
                  GO
                </button>
              </div>
            </div>
            {inputFocus && (
              <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-50"></div>
            )}
          </div>
        ) : (
          <div className="relative rounded-[50px] w-[641px] h-[47px] bg-custom-input-color p-1 ">
            <div
              style={{ width: `${progress}%` }}
              className="rounded-[50px] h-full bg-gray-950"
            >
              {" "}
            </div>

            <div className="absolute top-0 left-4 h-full flex items-center px-2 text-white">
              {progress}%{" "}
              <p className=" ml-5  ">You will have your answers soon... </p>
            </div>
          </div>
        )}
      </div>

      <BottomBar />
    </div>
  );
};
