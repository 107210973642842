
const BottomBar = () => {
  return (
    <div className='mt-[55px]'>
        <h1 className=' text-[100px] font-inter text-center font-bold text-white'>manybr<span className='text-green-500 font-inter 	'>ai</span>ns</h1>
        <p className='text-center text-green-500 mt-[80px]'>BY</p>
        <h1 className='text-center font-normal text-5xl	leading-10 text-[30px]  font-lobster text-white'>Less Boring Data</h1>
    </div>
  );
};

export default BottomBar;