import React, { createContext, useContext, useState } from 'react';

// Create a context for the global state
const GlobalStateContext = createContext();

// Custom hook to access the global state
export const useGlobalState = () => useContext(GlobalStateContext);

// GlobalStateProvider component to wrap your application
export const GlobalStateProvider = ({ children }) => {
  // Define the global state using useState
  const [globalState, setGlobalState] = useState({
    counter: 0,
    message: "Hello, world!"
  });

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};