import React, { useState,useEffect } from 'react';
import axios from 'axios'; // Import Axios
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from './url';
import BottomBar from './bottombar';


const RegisterPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const HandleRegister = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(SERVER_URL+'api/register', { username, email, password }); // Send registration request
      console.log(response.data); 
      if(response.status==200){
        
        
      }
    } catch (error) {
      setError('Registration failed. Please try again.'); // Set error state if registration fails
    }
    setLoading(false);
  };

  return (
    <>
      <div className='bg-custom-background min-h-screen flex flex-col justify-center items-center'>
        <div className="flex flex-row ">
          <button 
            onClick={ ()=>  navigate('/login') }
            className=" left-[32px] w-[210px] h-[31px]  bg-custom-black    rounded-full hover:bg-gray-400 hover:z-10 text-white font-bold  mb-4">
            LOG IN
          </button>
          <button
            onClick={() => navigate("/register")}
            className=" w-[210px] h-[31px] bg-gray-100 rounded-full hover:bg-gray-400   text-black font-bold  mb-4 ml-[-31px]"
          >
            SIGN UP
          </button>
        </div>
        <div className="w-[389px] h-[230px] bg-custom-blue rounded-[15px]">
          <div className="bg-custom-blue flex flex-col justify-center items-center rounded-lg p-6">
            <div>
              <input
                className="w-[332px] h-[38px] rounded-[50px] bg-custom-input-color text-left p-4 text-white"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <input
                className="w-[332px] h-[38px] rounded-[50px] bg-custom-input-color text-left p-4 text-white mt-3"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                className="w-[332px] h-[38px] rounded-[50px] p-4 bg-custom-input-color text-white mt-3"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="w-[332px] h-[27px] bg-custom-black rounded-[15px] hover:bg-gray-400 text-white font-bold mt-8" onClick={HandleRegister}>
              {loading? 'LOADING...' : 'SIGN UP'}
            </button>
            
          </div>
        </div>
        
        <BottomBar />

      </div>
    </>
  );
};

export default RegisterPage;
