import React, { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import { SERVER_URL } from "./url";
import axios from "axios";

const EXPERT_OPTIONS = [
  "Expert 1",
  "Expert 2",
  "Expert 3",
  "Expert 4",
  "Expert 5",
  "Apple",
  "Banana",
  "Cherry",
  "Date",
  "Elderberry",
  "Fig",
  "Grape",
  "Honeydew",
  "Indian Fig",
  "Jackfruit",
  "Kiwi",
  "Lemon",
  "Mango",
  "Nectarine",
  "Orange",
  "Papaya",
  "Quince",
  "Raspberry",
  "Strawberry",
  "Tangerine",
  "Ugli Fruit",
  "Vanilla Bean",
  "Watermelon",
  "Xigua",
  "Yellow Passion Fruit",
  "Zucchini",
];

export const Dropdown = ({
  prompts,
  setPrompts,
  expertTags,
  setExpertTags,
  recentOptions,
  setRecentOptions,
  expertOptions,
  setExpertOptions,
  tag,
  index,
  isReversed = false,
  lowOpacity = false,
}) => {
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [isFocused, setIsFocused] = useState(tag.autoFocus);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hideRecent, setHideRecent] = useState(false);
  const [actualPrompt, setActualPrompt] = useState("");
  const wrapperRef = useRef(null);
  const [outsideClick] = useOutsideAlerter(wrapperRef);

  useEffect(() => {
    const options = expertOptions.filter((option) => !prompts.includes(option));
    if (currentPrompt) {
      setHideRecent(true);
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(currentPrompt.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setHideRecent(false);
      setFilteredOptions(options);
    }
  }, [currentPrompt, prompts]);

  useEffect(() => {
    setActualPrompt(tag.actualValue);
    setCurrentPrompt(tag.currentValue);
  }, [expertTags]);

  useEffect(() => {
    if (localStorage.getItem("recentOptions")) {
      setRecentOptions(JSON.parse(localStorage.getItem("recentOptions")));
    }
  }, []);

  const handlePromptChange = (e) => {
    setCurrentPrompt(e.target.value);
  };

  const selectPrompt = (prompt) => {
    if (prompts.includes(prompt)) return;
    setCurrentPrompt(prompt);
    setIsFocused(false);
    setActualPrompt(prompt);
    setPrompts((state) => [...state, prompt]);
    setFilteredOptions([]);
    setRecentOptions((state) => {
      if (state.some((opt) => opt.toLowerCase() === prompt.toLowerCase()))
        return state;
      if (state.length >= 3) state.pop();
      localStorage.setItem("recentOptions", JSON.stringify([prompt, ...state]));
      return [prompt, ...state];
    });
    setExpertTags((state) => {
      state[index].actualValue = prompt;
      state[index].currentValue = prompt;
      return state;
    });
  };

  const removePrompt = () => {
    setPrompts((state) => state.filter((_prompt) => actualPrompt !== _prompt));
    setCurrentPrompt("");
    setActualPrompt("");
    setExpertTags((state) => {
      state[index].actualValue = "";
      state[index].currentValue = "";
      return state;
    });
    if (tag.canBeDeleted) {
      setExpertTags((state) => state.filter((_, i) => i !== index));
    }
  };

  const handleBlur = (e) => {
    if (e.target.value) {
      const found = expertOptions.find(
        (option) => option.toLowerCase() === e.target.value.toLowerCase()
      );
      if (found) {
        selectPrompt(found);
      } else {
        selectPrompt(e.target.value);
        addExpert(e.target.value);
        setExpertOptions(state => [...state, e.target.value]);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.key === "Enter" && e.target.value) {
      const found = expertOptions.find(
        (option) => option.toLowerCase() === e.target.value.toLowerCase()
      );
      if (found) {
        selectPrompt(found);
      } else {
        selectPrompt(e.target.value);
        addExpert(e.target.value);
        setExpertOptions(state => [...state, e.target.value]);
      }
    }
  };

  const addExpert = async (expert) => {
    if (expert === "") return;
    try {
      // Get expert options from backend
      const payload = { name: expert };
      const { data } = await axios.post(SERVER_URL + "api/experts", payload);
      console.log(data.message);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="relative w-80 shrink-0" ref={wrapperRef}>
      <input
        value={currentPrompt}
        onChange={handlePromptChange}
        onKeyDown={handleKeyDown}
        placeholder={tag.placeholder}
        className={
          lowOpacity && !actualPrompt
            ? "rounded-full px-4 py-1.5 outline-none font-interLight bg-custom-result-chatbg text-white w-full block border border-custom-input-color focus:border-white bg-opacity-25 border-gray-600"
            : "rounded-full px-4 py-1.5 outline-none font-interLight bg-custom-result-chatbg text-white w-full block border border-custom-input-color focus:border-white"
        }
        onFocus={() => setIsFocused(true)}
        // onBlur={handleBlur}
        disabled={actualPrompt !== ""}
        autoFocus={tag.autoFocus}
      />
      {actualPrompt === "" ? (
        <button
          className="absolute top-1/2 -translate-y-1/2 right-3 text-white font-interLight"
          onClick={() => (isFocused ? setIsFocused(false) : setIsFocused(true))}
        >
          <svg
            width="14"
            height="7"
            viewBox="0 0 14 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6985 0.75L7 6.30193L1.3015 0.75L12.6985 0.75Z"
              stroke="white"
            />
          </svg>
        </button>
      ) : (
        <button
          className="absolute top-1/2 -translate-y-1/2 right-3 text-white font-interLight"
          onClick={removePrompt}
        >
          X
        </button>
      )}
      {isFocused && !outsideClick && (
        <>
          {filteredOptions.length > 0 && (
            <div
              className="fixed bg-custom-result-chatbg font-inter text-white font-extralight rounded-lg shadow-lg mt-2 max-h-60 w-80 z-50 overflow-y-auto px-2 border border-custom-input-color"
              style={
                isReversed
                  ? {
                      left: wrapperRef.current.getBoundingClientRect().x,
                      bottom:
                        wrapperRef.current.getBoundingClientRect().height + 80,
                    }
                  : { left: wrapperRef.current.getBoundingClientRect().x }
              }
            >
              {recentOptions.length !== 0 && !hideRecent && (
                <>
                  <p className="flex items-center text-white font-bold text-xs mt-2 mb-1 px-2 pt-2">
                    RECENT
                    <button
                      className="ms-auto text-[11px] font-interLight font-normal"
                      onClick={() =>
                        setRecentOptions(() => {
                          localStorage.setItem(
                            "recentOptions",
                            JSON.stringify([])
                          );
                          return [];
                        })
                      }
                    >
                      CLEAR ALL
                    </button>
                  </p>
                  <ul className="max-h-48 px-2">
                    {recentOptions.map((option, index) => (
                      <li
                        key={"recent" + index}
                        className="flex items-center font-interLight cursor-pointer px-1 py-0.5 hover:bg-custom-black hover:border hover:border-white"
                      >
                        <div
                          className="w-full"
                          onClick={() => selectPrompt(option)}
                        >
                          {option}
                        </div>
                        <button
                          className="ms-auto text-xs z-10 font-interLight font-normal"
                          onClick={() =>
                            setRecentOptions((state) => {
                              const newState = state.filter(
                                (_, i) => i !== index
                              );
                              localStorage.setItem(
                                "recentOptions",
                                JSON.stringify(newState)
                              );
                              return newState;
                            })
                          }
                        >
                          X
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <p className="text-white text-xs font-extrabold mt-2 mb-1 sticky top-0 bg-custom-result-chatbg px-2 py-1">
                A-Z
              </p>
              <ul className="max-h-48 px-2">
                {filteredOptions.map((option, index) => (
                  <li
                    key={"expert" + index}
                    onClick={() => selectPrompt(option)}
                    className="cursor-pointer px-1 py-0.5 hover:bg-custom-black hover:border hover:border-white"
                  >
                    {option
                      .split(new RegExp(`(${currentPrompt})`, "gi"))
                      .map((part, i) => (
                        <span
                          key={i}
                          className={
                            part.toLowerCase() === currentPrompt.toLowerCase()
                              ? "font-bold"
                              : "font-interLight"
                          }
                        >
                          {part}
                        </span>
                      ))}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};
