export default function (expertChoices, question, prevMessages = []) {
  // Join expert choices with commas and 'and' before the last item
  const formattedExpertChoices = expertChoices.length > 1
      ? expertChoices.slice(0, -1).join(', ') + ' and ' + expertChoices[expertChoices.length - 1]
      : expertChoices[0];
  
  let formatQuestion;
  if (prevMessages.length == 0) {
    const formattedStr = `Please assume and act as if you are an experienced and expert ${formattedExpertChoices}, can you answer this question for me? ${question}`;

    formatQuestion = {
      'openai': formattedStr,
      'genai': formattedStr,
      'anthropic': formattedStr,
    }
  }
  else {

    formatQuestion = { 
      'openai':   formatScript(formattedExpertChoices, question, prevMessages, 'openai'),
      'genai':    formatScript(formattedExpertChoices, question, prevMessages, 'google'),
      'anthropic': formatScript(formattedExpertChoices, question, prevMessages, 'anthropic'),
    }
  }

  return formatQuestion;

}

const formatScript = (formattedExpertChoices, question, prevMessages, type = 'openai') => {
  let prevResponseStr = "";

  console.log('prevMessage = ', prevMessages)

  prevMessages.forEach((message, index) => {
    prevResponseStr += `
       User(me): ${message['user']['content']} \n\n
       Mr. White ${type === 'openai' ? ' (you)' : ''}: ${message['answer']['openai']['content']} \n\n
       Mr. Green: ${type === 'google' ? ' (you)' : ''}: ${message['answer']['google']['content']} \n\n
       Mr. Blue: ${type === 'anthropic' ? ' (you)' : ''}: ${message['answer']['anthropic']['content']} \n\n
       `
  })

  let templateScript = `
    Hello! I would like to help me continue a conversation.

    In this conversation, you are ${type === 'openai' ? "Mr. White" : type === 'google' ? "Mr. Green" : "Mr. Blue"}, and your previous commentary is listed under that name in the transcript below, along with comments from the other people involved in the conversation. The other three characters are me (the user), Mr. Blue, and Mr. Green.  Here is the transcript so far: 

    -- start of transcript
    ${prevResponseStr}

    ${question}
    -- end of transcript

    I would like you to continue the conversation quoted in the transcript above, with a thoughtful reply that includes two elements. 

    First, I would like you to apply critical thinking, and to investigate any statements of fact or truth in the above conversation and the included statements, specifically on the responses of ${type === 'openai' ? "Mr. Green and Mr. Blue" : type==='google' ? "Mr. White and Mr. Blue" : "Mr.White and Mr Green" } (in relation to the question asked by me, the user). Please include 50 words or less on the results of your investigative fact checking in your response, as well as any other critical feedback on any statements made by any other member of the conversation in the transcript. Please separate this element in your response, give it a single title label “Fact Check’, (do not use this title label more than once), and place it at the end of your response. 

    I would also like you to directly answer (in context) the last question by the user (me) in the transcript above, which is a follow up question to the previous statements made by the other members of the conversation. 

    Please assume and act as if you are and experienced and expert ${formattedExpertChoices}, using as many words as you would like, and reply to this question: 
    
    ${question}

    Please do not make any references to my request for you to continue this conversation, just give your response naturally as if you were in the room and talking directly with all of the participants. Also, please do not repeat yourself or anything that has been mentioned in the conversation already, unless it is critical to illustrating a new perspective.
    `
    return templateScript;
}