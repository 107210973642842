import { Routes, Route, Navigate } from 'react-router-dom';
import PreLoginPage from './component/prelogin';
import LoginPage from './component/login';
import RegisterPage from './component/register';
import ResultPage from './component/result';
import { PromptPage } from './component/prompt';
 
const AppRoutes = () => {
  // Check if the user has a JWT token
  const hasToken = localStorage.getItem('access_token');

  return (
   
    <Routes>
      <Route path="/prelogin" element={<PreLoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/" element={hasToken ? <PromptPage /> : <PreLoginPage />} />
      <Route path="/result" element={hasToken ? <ResultPage /> : <PreLoginPage />} />
    </Routes>
 
  );
};

export default AppRoutes;