import React, { useState, useRef, useEffect } from "react";
import { SERVER_URL } from "./url";
import axios from "axios";
import { Dropdown } from "./dropdown";
import ReactTextareaAutosize from "react-textarea-autosize";
import formatExpertOptions from "../util/formatExpertOptions";

const ResultPage = () => {
  const [userName, setUserName] = useState("");
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [input1Value, setInput1Value] = useState("");
  const [shiftPressed, setShiftPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const [prompts, setPrompts] = useState([]);
  const [expertOptions, setExpertOptions] = useState([]);
  const [loadingExpertOpts, setLoadingExpertOpts] = useState(false);
  const [expertTags, setExpertTags] = useState([
    {
      actualValue: "",
      currentValue: "",
      placeholder: "Add another expert option...",
      canBeDeleted: false,
      lowOpacity: true,
    },
  ]);
  const [recentOptions, setRecentOptions] = useState([]);
  const scrollContainerRef = useRef(null);

  const messageContainerRef = useRef([]);
  const [isOverflowed, setIsOverflowed] = useState(
    Array(messages.length).fill().map(() => Array(3).fill(false))
  );
  const [expandedMessages, setExpandedMessages] = useState(
    Array(messages.length).fill().map(() => Array(3).fill(false))
  );
  
  
  const userQuestionRefs = useRef([]);
  // State to track whether the mouse is over each question
  const [isOverQuestions, setIsOverQuestions] = useState(
    Array(messages.length).fill(false)
  );
  // State to track expanded state for each question
  const [expandedQuestions, setExpandedQuestions] = useState(
    Array(messages.length).fill(false)
  );


  useEffect(() => {
    if (localStorage.getItem("result")) {
      console.log("it work ");

      const question = localStorage.getItem("question");
      const prompts = JSON.parse(localStorage.getItem("prompts"));
      console.log(prompts);
      // setInputValue(value);
      setUserName(localStorage.getItem("username"));
      setPrompts(prompts);
      setExpertTags((state) => {
        return [
          ...prompts.map((p) => ({
            actualValue: p,
            currentValue: p,
            placeholder: "Choose/type in your expert...",
            canBeDeleted: true,
            lowOpacity: false,
          })),
          ...state
        ];
      });

      localStorage.removeItem("question");
      localStorage.removeItem("prompts");
      localStorage.removeItem("result");

      handleSendMessage(question, prompts);
    }
    const getExpertOptions = async () => {
      if (localStorage.getItem("experts")) {
        setExpertOptions(JSON.parse(localStorage.getItem("experts")));
        return;
      }
      setLoadingExpertOpts(true);
      try {
        // Get expert options from backend
        const { data } = await axios.get(SERVER_URL + "api/experts");
        console.log(data.options);
        setExpertOptions(data.options);
        setLoadingExpertOpts(false);
      } catch (e) {
        console.error(e);
      }
    };
    getExpertOptions();

  }, []);

  useEffect(() => {

    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

    //messageContainerRef.current = messageContainerRef.current.slice(0, messages.length);
    messageContainerRef.current = messages.map((message) => {
      return Array(3).fill(null);
    })

    setExpandedMessages(Array(messages.length).fill().map(() => Array(3).fill(false)));

    
   
    userQuestionRefs.current = userQuestionRefs.current.slice(0, messages.length);
    const newIsOverQuestions = userQuestionRefs.current.map(
      (ref) => ref && ref.scrollHeight > ref.clientHeight + 10
    );
    setIsOverQuestions(newIsOverQuestions);
  }, [messages]);

  useEffect(() => {
    const newIsOverflowed = messageContainerRef.current.map((container) =>
      container.map((ref) => ref && ref.scrollHeight > ref.clientHeight)
    );
    setIsOverflowed(newIsOverflowed);
  }, [messageContainerRef.current])

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setInput1Value(e.target.value);
  };

  const onClickGo = () => {
    handleSendMessage();
  };

  const handleSendMessage = async (value, value2) => {
    if (loading) {
      return;
    }

    let question = value || inputValue;
    let experts = value2 || prompts;

    console.log(question);
    console.log(experts);

    if ( question && experts.length !== 0) {
      
      let sendText = formatExpertOptions(experts, question, messages);

      setLoading(true);

      setMessages([
        ...messages,
        // { role: "user", content: question },
        // { role: "AI", content: "Typing ..." },
        {
          "user" : { content: question },
          "AI" : { content: "TYPING ..." },
        }
      ]);

      setInputValue("");
      setInput1Value("");

      try {
        console.log(sendText);
        const url = SERVER_URL + "callApi/";
        console.log(url);
        const payload = {
          user_input: sendText,
        };

        const response = await axios.post(url, payload);
        if (response.status === 200 && response.data["error"] === undefined) {
          let gemini = response.data["gemini"];
          let chatgpt = response.data["chatgpt"];
          let anthropic = response.data["anthropic"];

          console.log("1");

          // use '<br>' instead of '\n' to make the chat look better
          // gemini = gemini.replace(/\n/g, "<br>");
          // chatgpt = chatgpt.replace(/\n/g, "<br>");
          // anthropic = anthropic.replace(/\n/g, "<br>");

          setMessages([
            ...messages,
            // { role: "user", content: question },
            // { role: "gemini", content: gemini },
            // { role: "chatgpt", content: chatgpt },
            // { role: "anthropic", content: anthropic },
            {
              "user" : { content: question },
              "answer" : {
                "openai" : { content: chatgpt },
                "google" : { content: gemini },
                "anthropic" : { content: anthropic },
              }
            }
          ]);
          console.log("2");
        } else {
          setMessages([
            ...messages,
            // { role: "user", content: question },
            // { role: "AI", content: "Error. Please try again later" },
            {
              "user" : { content: question },
              "AI" : { content: "Error. Please try again later" },
            }
          ]);
        }

        setLoading(false);
      } catch (error) {
        // console.error("Error:", error);
        setMessages([
          ...messages,
          // { role: "user", content: question },
          // { role: "AI", content: "Error. Please try again later" },
          {
            "user" : { content: question },
            "AI" : { content: "Error. Please try again later" },
          }
        ]);
        setLoading(false);
      }
    }
  };

  // const saveChatHistory = async (userMessage, botResponse) => {
  //   try {
  //     const apiUrl = SERVER_URL + "save_message/";
  //     const payload = {
  //       user_message: userMessage,
  //       bot_response: botResponse,
  //     };
  //     const headers = {
  //       user: username,
  //     };

  //     await axios.post(apiUrl, payload, { headers });
  //   } catch (error) {
  //     console.error("Error saving chat history:", error);
  //   }
  // };

  const keyDownEvent = (event) => {
    if (event.keyCode === 16 && event.key === "Shift") {
      setShiftPressed(true);
      return;
    }
    if (event.keyCode === 13 && event.key === "Enter" && !shiftPressed) {
      event.preventDefault();
      setMessages([]);
      handleSendMessage();
    }
  };
  const keyDownEventReply = (event) => {
    if (event.keyCode === 16 && event.key === "Shift") {
      setShiftPressed(true);
      return;
    }
    if (event.keyCode === 13 && event.key === "Enter" && !shiftPressed) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const keyUpEvent = (event) => {
    if (event.keyCode === 16 && event.key === "Shift") setShiftPressed(false);
  };



  // useEffect(() => {
  //   const token = localStorage.getItem("access_token");

  //   if (token) {
  //     const decoded = jwtDecode(token);
  //     setDecodedToken(decoded);
  //     setUsername(decoded.username); // Extract username from decoded token
  //     const userName = decoded.username;

  //     const apiUrl = Url() + "get_chat_history/";
  //     const queryParams = new URLSearchParams({ user: userName }).toString();
  //     const urlWithParams = `${apiUrl}?${queryParams}`;

  //     axios
  //       .get(urlWithParams)
  //       .then((response) => {
  //         setMessages(response.data.chat_history);
  //         const model = localStorage.getItem("model");
  //         setModel(model);

  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching chat messages:", error);
  //       });
  //   }
  // }, []);
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");

    window.location.href = "/prelogin";
  };

  const addPrompt = (e) => {
    if (prompts.length === expertTags.length) {
      setExpertTags((state) => [
        ...state,
        {
          actualValue: "",
          currentValue: "",
          placeholder: "Add another expert option...",
          canBeDeleted: true,
          autoFocus: true,
          lowOpacity: false,
        },
      ]);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-custom-result-black">
      <div className="flex flex-row ">
        <nav className="flex-1 bg-custom-blue p-3 flex justify-between  text-white">
          <div className="flex items-center">
            <div className="flex items-center mr-4">
              <a href="/">
                {" "}
                <span className="text-2xl font-bold font-inter">
                  manybr<span className="text-green-500">ai</span>ns
                </span>
              </a>
            </div>
            <div className="flex !items-center">
              {/* <input
                id="input_question"
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={keyDownEvent}
                className="border bg-custom-input-color w-[450px] px-4 rounded-[50px] h-[32px] text-xs "
                type="text"
                placeholder="Who is the greatest basketball player of all time? Use no more than 200..."
              /> */}
              <a href="/">
                {" "}
                <button className="border bg-custom-input-color w-[95px] ml-3 h-[32px] text-xs rounded-[50px] ">
                  {" "}
                  START NEW
                </button>
              </a>
            </div>
          </div>
          <div className=" flex self-end mt-2 mb-2">
            <div className="flex justify-center items-center rounded-[50px] bg-gray-950 py-1 px-6 mr-3">
              <button
                className="text-white text-[12px] font-inter"
                onClick={logout}
              >
                LOG OUT
              </button>
            </div>
            <div className="flex justify-center items-center rounded-[50px] bg-gray-950 py-1 px-6">
              <h1 className="text-white text-[12px] font-inter">
                {userName.toUpperCase()}
              </h1>
            </div>
          </div>
        </nav>
        <a href="/">
          <img
            className=" m-3 w-[45px] h-[45px]"
            src={require("../image/brainroom 2 2.png")}
          />
        </a>
      </div>
      <div className="flex-1 bg-custom-result-background rounded-[15px] p-3 flex flex-col justify-center items-center h-[calc(100vh-120px)]">
        <div className="w-[96%] h-full p-3 relative flex flex-col">
          <div
            className="relative mx-auto  w-[calc(85%_-_36px)]  flex-1  pt-5    overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
            ref={chatContainerRef}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex w-full flex-col ${expandedMessages[index] && expandedMessages[index].some((els) => els === true) ? '':'h-full'}`}
              >
                <div 
                  ref={(el) => (userQuestionRefs.current[index] = el)}
                  className={`p-3 rounded-[15px] bg-custom-result-chatbg text-[#B9B9B9] mb-2 ${!expandedQuestions[index] ? "max-h-[66px] overflow-hidden text-ellipsis":""} relative pr-[40px]`}>
                  {/* <span className="font-bold font-inter text-base">
                    USER:{" "}
                    <span className="font-normal">{`${message.user.content}`}</span>
                  </span> */}
                   <strong className="mr-2">USER: </strong>{message.user.content}

                    {isOverQuestions[index] && (
                      <button
                        className="w-[28px] h-[28px] rounded-[34px] bg-[#444444] flex justify-center items-center absolute top-1/2 -translate-y-1/2 right-2 "
                        onClick={() => {
                          const newExpandedQuestions = [...expandedQuestions];
                          newExpandedQuestions[index] = !newExpandedQuestions[index];
                          setExpandedQuestions(newExpandedQuestions);
                        }}
                      >
                        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"
                          transform={!expandedQuestions[index] ? "rotate(0)" : "rotate(180)" } >
                          <path d="M9 7C9 6.44772 8.55228 6 8 6C7.44772 6 7 6.44772 7 7H9ZM7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7V8H9V7H7Z" fill="#FFFFFF"/>
                        </svg>
                      </button>
                    )}
                    
                </div>
                {message.AI ? (
                  <div className="flex flex-row mt-3">
                    <div className={`w-[15px] h-[15px] rounded-[12px] mr-2 ml-2 bg-white`} />
                    <div className={`w-[15px] h-[15px] rounded-[12px] mr-2 bg-[#62EFBB]`} />
                    <div className={`w-[15px] h-[15px] rounded-[12px] mr-2 bg-[#003D63]`} />
                    <span className="font-normal font-inter text-xs text-[#B9B9B9] ml-3">
                      {message.AI.content}
                    </span>
                  </div>
                ): (
                  <div className={`flex w-full gap-1 pb-2 ${expandedMessages[index] && expandedMessages[index].some((els) => els === true) ? '':'h-full'}`}> 
                    {
                      Object.keys(message.answer).map((key, idCol) => (
                        <div 
                          key={`${key}-${idCol}`}
                          className={`flex flex-col flex-1 h-full p-3 rounded-[15px] bg-custom-result-chatbg relative`}>
                          <div className="flex justify-between items-center">
                            <div className="flex items-center">
                              <div className={`w-[15px] h-[15px] rounded-[12px] mr-2
                                  ${key === "openai" ? "bg-white" : (key === "google" ? "bg-[#62EFBB]" : "bg-[#003D63]")} `} />
                              <p className="font-normal font-inter text-xs text-white">
                                {key === "openai" ? "MR. WHITE" : (key === "google" ? "MR. GREEN" : "MR. BLUE")}
                              </p>
                            </div>
                            <p className="font-thin font-inter text-xs text-white">{key.toUpperCase()}</p>
                          </div>
                          <div className="h-[1px] bg-[#2C2C2C] mt-3"/>
                          <div 
                            ref={(el) => {
                              messageContainerRef.current[index][idCol] = el;
                            }}
                            className={`grow mt-4 mx-5  ${expandedMessages[index] && expandedMessages[index][idCol] && expandedMessages[index][idCol] ? '':'min-h-[350px] h-[250px] overflow-y-auto'}  
                                        font-normal font-inter text-sm text-[#767676] whitespace-pre-line whitespace-pre-wrap`}
                            style={{ scrollbarWidth: "none" }}>
                            {message.answer[key].content}
                          </div>
                          {isOverflowed[index][idCol] && (
                            <button
                              className="absolute bottom-3 right-2 text-white font-interLight"
                              onClick={() => {
                                const newExpandedMessages = [...expandedMessages];
                                newExpandedMessages[index][idCol] = !newExpandedMessages[index][idCol];
                                setExpandedMessages(newExpandedMessages);
                              }}
                            >
                              <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"
                                transform={!expandedMessages[index][idCol] ? "rotate(0)" : "rotate(180)"} >
                                <path d="M9 7C9 6.44772 8.55228 6 8 6C7.44772 6 7 6.44772 7 7H9ZM7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7V8H9V7H7Z" fill="#767676"/>
                              </svg>
                            </button>
                          )}
                        </div>
                      ))
                    }
                   
                  </div>
                )
                }
                                
              </div>
            ))}
            
            {/* {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.role === "user"
                    ? "justify-start mb-4 mt-4"
                    : "justify-start mb-2 mt-2"
                }`}
              >
                <div className={`p-1 `}>
                  <div className="relative z-10 text-[#B9B9B9]">
                    {message.role === "user" ? (
                      <span className="font-bold font-inter">
                        USER:{" "}
                        <span className="font-normal">{`${message.content}`}</span>
                      </span>
                    ) : null}
                    {message.role === "chatgpt" ? (
                      <span className="font-bold font-inter">
                        GPT:{" "}
                        <span className="font-normal whitespace-pre-line whitespace-pre-wrap">{`${message.content}`}</span>
                      </span>
                    ) : null}
                    {message.role === "gemini" ? (
                      <span className="font-bold font-inter">
                        GEMINI:{" "}
                        <span className="font-normal whitespace-pre-line whitespace-pre-wrap">
                          {message.content}
                        </span>
                      </span>
                    ) : null}
                    {message.role === "anthropic" ? (
                      <span className="font-bold font-inter">
                        CLAUDE:{" "}
                        <span className="font-normal whitespace-pre-line whitespace-pre-wrap">{`${message.content}`}</span>
                      </span>
                    ) : null}
                    {message.role === "AI" ? (
                      <span className="font-normal font-inter">{`${message.content}`}</span>
                    ) : null}
                  </div>
                  {(message.role === "chatgpt" ||
                    message.role === "gemini" ||
                    message.role === "anthropic") && (
                    <div
                      className={`${
                        message.role === "chatgpt"
                          ? "bg-[#4981EC]"
                          : "bg-[#EF8C62]"
                      } h-[1px] mx-auto flex mt-4 mb-1`}
                    />
                  )}
                </div>
              </div>
            ))} */}
          </div>
          <div className="w-full  bg-custom-result-background z-50">
            <div className="h-[1px] mx-auto bg-[#484848] flex mb-4" />
            <div className="mx-auto bg-custom-result-chatbg rounded-[15px] flex flex-col justify-center items-center gap-2 py-2">
              <div className="!relative flex items-center  w-[calc(85%_-_36px)]">
                <ReactTextareaAutosize
                  id="input_reply"
                  type="text"
                  className="w-full bg-black rounded-[15px] px-4 pe-24 py-3 text-[#B9B9B9] font-interLight border-none focus:outline-none resize-none"
                  placeholder="Reply here..."
                  value={input1Value}
                  onChange={handleInputChange}
                  onKeyDown={keyDownEventReply}
                  onKeyUp={keyUpEvent}
                  maxRows={6}
                />
                <button
                  className="!absolute right-[5px] bg-custom-result-chatbg w-[75px] h-[30px] rounded-[15px] hover:bg-gray-600 text-white font-inter font-bold py-1 place-self-end mb-2 me-2"
                  onClick={onClickGo}
                  disabled={loadingExpertOpts}
                >
                  GO
                </button>
              </div>

              <div className="flex justify-center flex-col items-center w-[calc(85%_-_36px)]">
                <div className="w-full">
                  <div className="flex items-center w-full">
                    <div
                      className="flex w-full items-center bg-black rounded-[15px] p-1.5 px-3 gap-2 overflow-x-scroll no-scrollbar"
                      ref={scrollContainerRef}
                    >
                      {!loadingExpertOpts ? (
                        <>
                          {expertTags.map((tag, i) => (
                            <Dropdown
                              prompts={prompts}
                              setPrompts={setPrompts}
                              tag={tag}
                              expertTags={expertTags}
                              setExpertTags={setExpertTags}
                              expertOptions={expertOptions}
                              setExpertOptions={setExpertOptions}
                              index={i}
                              recentOptions={recentOptions}
                              setRecentOptions={setRecentOptions}
                              key={"dropdown" + i}
                              isReversed={true}
                              lowOpacity={tag.lowOpacity}
                            />
                          ))}
                        </>
                      ) : (
                        <p className="text-[#FFFFFF99] py-1.5 font-interLight font-xl">Loading your experts...</p>
                      )}
                      <button
                        onClick={addPrompt}
                        className="bg-custom-result-chatbg ml-auto text-white rounded-full hover:bg-gray-600 flex items-center justify-center shrink-0 disabled:opacity-50"
                        style={{
                          fontSize: "30px",
                          width: "50px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingBottom: "5px",
                        }}
                        disabled={expertTags.length >= 5 || loadingExpertOpts}
                        id="add-prompt"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
