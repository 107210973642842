import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomBar from "./bottombar";
import { SERVER_URL } from "./url";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(SERVER_URL + "api/token/", {
        username,
        password,
      }); // Send registration request
      console.log(response.data); // Log the response for debugging
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("username", username);
      // navigate('/')
      window.location.href = "/";      
    } catch (error) {
      setError("Login failed. Please try again."); // Set error state if registration fails
    }
    setLoading(false);
  };

  const keyDownEvent = (event) => {
		if (event.keyCode === 13 && event.key === "Enter") {
			handleLogin();
		}
	}

  return (
    <>
      <div className="bg-custom-background min-h-screen flex flex-col justify-center items-center  ">
        <div className="flex flex-row ">
          <button className=" left-[32px] w-[210px] h-[31px]  bg-custom-black    rounded-full hover:bg-gray-400 text-white font-bold  mb-4 z-10">
            LOG IN
          </button>
          <button
            onClick={() => navigate("/register")}
            className=" w-[210px] h-[31px] bg-gray-100 rounded-full hover:bg-gray-400 hover:z-10  text-black font-bold  mb-4 ml-[-31px]"
          >
            SIGN UP
          </button>
        </div>
        <div className="w-[389px] h-[230px] bg-custom-blue rounded-[15px]">
          <div className=" bg-custom-blue  flex flex-col justify-center items-center  rounded-lg p-6">
            <div>
              <input
                id="input_username"
                className="w-[332px] h-[38px] rounded-[50px] p-4 bg-custom-input-color text-left  text-white  "
                type="text"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </div>
            <div>
              <input
                id="input_password"
                className="w-[332px] h-[38px] rounded-[50px] p-4 bg-custom-input-color text-white mt-3 "
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                onKeyDown={keyDownEvent}
              />
            </div>
            <button
              className="w-[332px] h-[27px] bg-custom-black  rounded-[15px]  hover:bg-gray-400 text-white font-bold mt-8"
              onClick={handleLogin}
            >
              {loading? "LOADING..." : "LOG IN"}
            </button>
            {error && 
              <div className="flex justify-center items-center py-1 px-2 mt-1">
                <h1 className="text-red-500 text-[18px] font-inter italic">{error}</h1>
              </div>
            }
            <div className="mt-4">
              <a className="text-gray-400 hover:text-gray-200" href="#">
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
        
        <BottomBar />

      </div>
    </>
  );
};

export default LoginPage;
