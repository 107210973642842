import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "./url";
import OtpInput from 'react-otp-input';

const PreLoginPage = () => {
  const [otp, setOtp] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState("");

  const navigate = useNavigate();
  
  useEffect(() => {

    async function callAPI() {
      
      const response = await axios.get(SERVER_URL + "api/code/");
      console.log(response);
      if (response.status === 200) {
        setCode(response.data.code);
      } else {
        setError("Connection is failed. Please try again.");
      }
    }
    callAPI();

  }, [])


  const onChangeEvent = async (otp) => {
    setOtp(otp);

    console.log(otp);
    
    if (otp.length === 4) {
      if (otp === code) {
        navigate("/login")
      }
      else {
        setError("Wrong code. Please try again.");
        setTimeout(function(){
          setError("");
          // setOtp('')
        },2000); //delay is in milliseconds 
      }  
    }
    
  }

  return (
    <>
      <div className="bg-custom-background min-h-screen flex flex-col  items-center ">
        <div className="flex justify-center items-center rounded-[50px] bg-gray-950 py-1 px-20 mt-[350px] mb-5">
          <h1 className="text-white text-[16px] font-inter font-bold">ENTER THE PASSCODE</h1>
        </div>

        <OtpInput
          value={otp}
          onChange={onChangeEvent}
          numInputs={4}
          renderSeparator={<span className="mr-3"/>}
          renderInput={(props) => <input {...props} />}
          inputStyle="bg-custom-blue !w-[67px] h-[76px] rounded-[15px] text-white font-inter font-bold text-[48px]"
        />

        {error && 
          <div className="flex justify-center items-center py-1 px-2 mt-2">
            <h1 className="text-red-500 text-[18px] font-inter italic">{error}</h1>
          </div>
        }
        
      </div>
      
    </>
  );
};

export default PreLoginPage;
